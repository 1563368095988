<template>
  <section class="company-container">
    <div class="custom-button-container">
      <TableSearch :tableTitles="tableTitles" @input="handleInput" />
      <Button @click="openModal" skin="primary">{{ $t('add') }}</Button>
    </div>

		<Tabs :syncRoute="true" :isRTL="isRTL">
			<Tab v-for="tab in tabsData" :key="tab.label" :label="$t(tab.label)" :to="`/admin/company${tab.path}`" exact>
				<Table
					v-if="companiesToShow.length > 0"
					class="table"
					:tableData="companiesToShow"
					:editOptions="{ remove: true, edit: true }"
					:isHidingId="false"
					idField="id"
					@editRow="openModal"
					@removeRow="removeRow"
				/>
				<div v-else>
					<h3>{{ $t('No companies to show') }}</h3>
				</div>
			</Tab>
		</Tabs>

		<Modal
			v-if="currCompany"
			@submit="saveCompany"
			@close="closeModal"
			:isConfirmDisabled="isConfirmDisabled"
			width="medium"
			:texts="{ headerPrimary: currCompany.id ? 'edit_company' : 'new_company' }"
		>
			<template v-slot:default>
				<div class="modal-body">
					<label class="name">
						{{ $t('company_name') }}
						<Input class="input" v-model="currCompany.name" :style="noMarginBottom" :flash="errors.includes('name')" />
					</label>
					<label class="name">
						{{ $t('default_name') }}
						<Input class="input" v-model="currCompany.default_name" :style="noMarginBottom" :flash="errors.includes('default_name')" />
					</label>
          <template v-if="isClient">
            <label>
              {{ $t('email') }}
              <Input class="input" v-model="currCompany.email" :style="noMarginBottom" :flash="errors.includes('email')" />
            </label>
            <label v-for="(input, idx) in ['phone', 'address']" :key="input + idx">
              {{ $t(input) }}
              <Input class="input" v-model="currCompany[input]" :style="noMarginBottom" />
            </label>
            <label>
              {{ $t('country') }}
              <Select
                :placeholder="`${$t('choose_country')}...`"
                v-model="currCompany.country"
                :disabled="countryList.length ? false : true"
                :style="noMarginBottom"
              >
                <SearchBar v-model="modalSearchBy.countryName" />
                <OptionList :options="countryList" />
              </Select>
              <ErrorMsg :isShown="errors.includes('country')" :text="`${$t('please_enter')} ${$t('country')}`" />
            </label>
            <label>
              {{ $t('state') }}
              <Select
                :placeholder="`${$t('choose_state')}...`"
                v-model="currCompany.state"
                :disabled="stateList.length ? false : true"
                :style="noMarginBottom"
              >
                <SearchBar v-model="modalSearchBy.stateName" />
                <OptionList :options="stateList" />
              </Select>
              <ErrorMsg :isShown="errors.includes('state')" :text="`${$t('please_enter')} ${$t('state')}`" />
            </label>
            <label>
              {{ $t('city') }}
              <Select
                :placeholder="`${$t('choose_city')}...`"
                v-model="currCompany.city"
                :disabled="cityList.length ? false : true"
                :style="noMarginBottom"
              >
                <SearchBar v-model="modalSearchBy.cityName" />
                <OptionList :options="cityList" />
            </Select>
            <ErrorMsg :isShown="errors.includes('city')" :text="`${$t('please_enter')} ${$t('city')}`" />
            </label>
          </template>
					<label>
						{{ $t('franchise') }}
						<Select :style="noMarginBottom" :placeholder="`${$t('choose_franchise')}...`" v-model="currCompany.franchise_id">
							<OptionList :options="franchiseList" />
						</Select>
					</label>
          <label>
            {{ $t('company_type') }}
            <Select :disabled="!!currCompany.id" :style="noMarginBottom" :placeholder="`${$t('choose_type')}...`" v-model="currCompany.type">
              <OptionList :options="companyTypes" />
            </Select>
            <ErrorMsg :isShown="errors.includes('type')" :text="`${$t('please_enter')} ${$t('type')}`" />
          </label>
          <label v-if="isClient" for="file-upload" class="custom-file-upload" @drop.prevent="insertDraggedImage" @dragover.prevent>
            {{ $t('logo') }}
            <input id="file-upload" type="file" @change="showAddedImage" />
            <div class="logo-preview">
              <img v-if="imgUrl" :src="imgUrl" alt="your image" />
              <div v-else class="logo-placeholder">
                <div class="plus-sign">+</div>
                Logo
              </div>
            </div>
          </label>
          <div class="settings" v-if="!currCompany.id">
            <div class="default-shift">
              <Checkbox class="default-shift-checkbox" v-model="isDefaultShifts" />
            </div>
            <span>{{ $t('add_default_shifts') }}</span>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import Tabs from '@/modules/common/components/Tabs/Tabs'
import Tab from '@/modules/common/components/Tabs/Tab'
import Select from '@/modules/common/components/Select'
import Button from '@/modules/common/components/Button'
import ErrorMsg from '@/modules/common/components/ErrorMsg'
import SearchBar from '@/modules/common/components/SearchBar'
import swalService from '@/modules/common/services/swalService'
import OptionList from '@/modules/common/components/OptionList'
import Input from '@/modules/common/components/Input'
import Modal from '@/modules/common/components/Modal'
import Table from '@/modules/common/components/customTable/Table'
import TableSearch from '../../common/components/customTable/TableSearch.vue'
import { getCompanies, saveCompany, removeCompany, getDefaultCompany, checkCompanyEmail, checkCompanyPhone } from '../services/companyService'
import { getWorldCountriesInfo } from '../../control-panel/services/countryInfoService'
import { getPermittedFranchises } from '../services/franchiseService'
import { getServiceProviders, saveServiceProvider, removeServiceProvider } from '../services/serviceProviderService'
import util from '../../common/services/utilService'
import cloudinaryService from '../../common/services/cloudinaryService'
import { mapGetters, mapActions } from 'vuex'
import Checkbox from '../../common/components/Checkbox.vue'

export default {
  name: 'Company',
  created() {
    this.loadPage()
  },
  data() {
    return {
      currCompany: null,
      isClient: false,
      companies: [],
      serviceProviders: [],
      tabsData: [{ label: 'companies', path: '' }, { label: 'management co.', path: '/management' }, { label: 'cleaning co.', path: '/cleaning' }],
      companyTypes: ['client', 'cleaning', 'management'],
      franchiseList: null,
      errors: [],
      imgUrl: '',
      logoFile: '',
      tableTitles: null,
      searchTxt: '',
      searchBy: '',
      modalSearchBy: {
        countryName: '',
        stateName: '',
        cityName: '',
      },
      worldCountriesInfo: null,
      isConfirmDisabled: false,
      isDefaultShifts: true,
      originalFields :{
				email: '',
				phone: '',
			}
    }
  },
  computed: {
    ...mapGetters(['isRTL']),
    companiesToShow() {
      const { currTab, searchTxt, searchBy } = this
      const isCompaniesTab = currTab === 'company'
      let infoToShow = JSON.parse(JSON.stringify(this.companies))

      if (!isCompaniesTab) {
        infoToShow = this.getServiceProvidersByType(currTab)
      }

      const companiesInfoToShow = util.filterArrayOfObjectsWithString(infoToShow, searchTxt, searchBy)

      if (isCompaniesTab) {
        return companiesInfoToShow.map((c) => {
          const company = { id: c.id, ...c }
          delete company.name_he
          delete company.logo
          return company
        })
      } else {
        return companiesInfoToShow.map((c) => ({ id: c.id, ...c }))
      }
    },
    currTab() {
      const { path } = this.$route
      if (path.includes('management')) return 'management'
      else if (path.includes('cleaning')) return 'cleaning'
      else return 'company'
    },
    countryList() {
      const { worldCountriesInfo, modalSearchBy, getFilteredItems } = this
      const { countryName } = modalSearchBy
      const countriesNames = Object.keys(worldCountriesInfo)
      return getFilteredItems(countriesNames, countryName)
    },
    cityList() {
      const { currCompany, modalSearchBy, getFilteredItems, getStateInfo } = this
      const { country, state } = currCompany
      if (!country || !state) return []
      const { cityName } = modalSearchBy
      const { cities } = getStateInfo(country, state)
      const citiesNames = cities.map((c) => c.name)
      return getFilteredItems(citiesNames, cityName)
    },
    stateList() {
      const { currCompany, modalSearchBy, worldCountriesInfo, getFilteredItems } = this
      const { country } = currCompany
      if (!country) return []
      const { stateName } = modalSearchBy
      const { states } = worldCountriesInfo[country]
      const statesNames = states.map((s) => s.name)
      return getFilteredItems(statesNames, stateName)
    },
    noMarginBottom() {
      return { marginBottom: 0 }
    },
  },
  methods: {
    ...mapActions(['loadUpdatedOptions']),
    async uploadImage() {
      if (!this.logoFile) return
      const url = await cloudinaryService.uploadImage(this.logoFile, 'logo')
      return url
    },
    insertDraggedImage(ev) {
      let file = ev.dataTransfer.items[0].getAsFile()
      this.logoFile = file
      this.imgUrl = window.URL.createObjectURL(file)
    },
    showAddedImage(e) {
      const file = e.target.files[0]
      this.logoFile = file
      this.imgUrl = window.URL.createObjectURL(file)
    },
    closeModal() {
      this.currCompany = null
      this.imgUrl = ''
      this.errors = []
    },
    async openModal(company) {
      this.franchiseList = await getPermittedFranchises()
      this.setCurrCompanyInfo(company)
    },
    setCurrCompanyInfo(company) {
      const { id, type, franchise_name } = company
      if (!id) {
        this.currCompany = getDefaultCompany()
      } else {
        if (!type) company.type = 'client'
        const currFranchise = this.getFranchise(franchise_name)
        company.franchise_id = currFranchise.id
        this.currCompany = company
        this.originalFields = {
					email: company.email,
					phone: company.phone,
				}
      }
    },
    async removeRow(id) {
      const result = await swalService.confirmMsg()

      if (!result.value) return
      try {
        if (this.currTab === 'company') await removeCompany(id)
        else await removeServiceProvider(id)
        swalService.deletedMsg()
        this.loadUpdatedOptions()
      } catch (err) {
        swalService.errorMsg()
      }
      this.loadPage()
    },
    async checkForm() {
      const { id, name, type, default_name, email, phone, country, state, city } = this.currCompany
      const isCityListEmpty = this.cityList.length === 0
      const { email: originalEmail, phone: originalPhone } = this.originalFields
      this.errors = []
      
      const validations = {
        isEmailValidPrm: (!id || email !== originalEmail) && email ? checkCompanyEmail(email) : true,
        isPhoneValidPrm: (!id || phone !== originalPhone) && phone ? checkCompanyPhone(phone) : true
      }
      
      !name && this.errors.push('name')
      !type && this.errors.push('type')
      !country && this.errors.push('country')
      if (country && !state) {
        this.errors.push('state')
      }
      if (!city && (country && state && !isCityListEmpty)) {
        this.errors.push('city')
      }
      if (!default_name || !util.stringInEnglish(default_name)) {
        this.errors.push('default_name')
      }
      if (!email || !util.isValidEmail(email)) {
        this.errors.push('email')
      }

      // Await asynchronous validations
      const [isEmailValid, isPhoneValid] = await Promise.all(Object.values(validations))

      // Push errors based on validation results
      if (isEmailValid === false) this.errors.push('email')
      if (isPhoneValid === false) this.errors.push('phone')
    },
    async saveCompany() {
      const { currCompany, isDefaultShifts } = this
      await this.checkForm()
      if (this.errors.length) return

      this.isConfirmDisabled = true

      // Prepare the company object for saving, and remove unwanted fields at once
      const company = { ...currCompany, isDefaultShifts }
      delete company.franchise_name

      // Remove unnecessary fields in a single step
      const isClientCompany = currCompany.type === 'client' || !currCompany.type
      if (isClientCompany) {
        delete company.type
        delete company.site_count
      } else {
        delete company.associated_companies
      }

      // Handle image upload and company save in parallel (image upload is optional)
      const imageUploadPromise = this.uploadImage().catch((err) => {
        console.warn('something went wrong getting logo url');
        return null; // Return null on failure
      })

      const companySavePromise = isClientCompany ? saveCompany(company) : saveServiceProvider(company)

      try {
        const [logo] = await Promise.all([imageUploadPromise, companySavePromise])
        // If logo upload succeeded, update the company logo
        if (logo) company.logo = logo
        this.isConfirmDisabled = false
        this.isDefaultShifts = true
        swalService.savedMsg(this.$t('Your work has been saved'))
        this.loadUpdatedOptions()
      } catch (err) {
        swalService.errorMsg()
        this.isConfirmDisabled = false
      }

      this.loadPage()
      this.closeModal()
    },
    async loadPage() {
      const prms = []
      prms.push(getCompanies())
      prms.push(getServiceProviders())
      prms.push(getWorldCountriesInfo())
      const [companies, serviceProviders, worldCountriesInfo] = await Promise.all(prms)
      this.worldCountriesInfo = worldCountriesInfo
      this.companies = companies
      this.serviceProviders = serviceProviders
      this.setTableTitles()
    },
    getServiceProvidersByType(type) {
      return this.serviceProviders.filter((c) => c.type === type)
    },
    setTableTitles() {
      if (!this.companies.length) return []
      this.tableTitles = Object.keys(this.companies[0]).filter((key) => key !== 'id')
    },
    handleInput(value, searchBy) {
      if (searchBy) this.searchBy = searchBy
      this.searchTxt = value
    },
    getFilteredItems(items, searchTxt) {
      if (!searchTxt) return items
      const filteredItems = items.filter((item) => item.toLowerCase().includes(searchTxt.toLowerCase()))
      return filteredItems
    },
    getFranchise(name) {
      return this.franchiseList.find((f) => f.name === name)
    },
    getStateInfo(country, state) {
      const { states } = this.worldCountriesInfo[country]
      const stateInfo = states.find((s) => s.name === state)
      return stateInfo
    },
  },
  watch: {
    'currCompany.type'(newType) {
      this.isClient = newType === 'client'
      if (!newType) return
      if (!this.isClient) {
        delete this.currCompany.address
        delete this.currCompany.country
        delete this.currCompany.state
        delete this.currCompany.city
        delete this.currCompany.email
        delete this.currCompany.phone
      }
    },
    'currCompany.country'(newVal, oldVal) {
      const { currCompany } = this
      if (!oldVal || !currCompany) return
      this.currCompany.state = ''
    },
    'currCompany.state'(newVal, oldVal) {
      const { currCompany } = this
      if (!oldVal || !currCompany) return
      this.currCompany.city = ''
    },
  },
  components: {
    Modal,
    Input,
    Table,
    Select,
    Tabs,
    Tab,
    ErrorMsg,
    Button,
    TableSearch,
    SearchBar,
    OptionList,
    Checkbox,
  },
}
</script>

<style lang="scss">
#app[dir='rtl'] .company-container .b-input {
  left: -10px !important;
}
</style>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';
.input {
  border: 1px solid gray;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  cursor: pointer;

  .logo-placeholder {
    display: flex;
    align-items: flex-end;
    width: 25%;
    justify-content: space-around;
    font-weight: $font-weight-bold;
  }
  .plus-sign {
    color: $white;
    border-radius: 50%;
    background-color: $black;
    @include flex-center;
    height: 20px;
    width: 20px;
  }
  .logo-preview {
    @include flex-center;
    img {
      height: 75%;
    }
    height: 13vh;
  }
}

.settings {
  span {
    margin-inline-start: 30px;
    margin-block-start: -7px;
  }
}

.custom-button-container {
  height: 80px;
  display: flex;
  align-items: center;
  margin-inline-end: 20px;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  right: 0;
  @include rtl {
    left: 0;
    right: unset;
  }
  span {
    @include modal-input-label;
    margin-bottom: 0px;
    font-size: 13px;
  }
  input {
    @include modal-input;
    margin-inline-start: 5px;
    margin-bottom: 0px;
    padding: 0 5px;
    width: 150px;
  }
  button {
    font-size: 12px;
    font-weight: bold;
  }
}

.company-container {
  position: relative;
}

.modal-body {
  @import '@/styles/vars.scss';
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px 10px;
  label {
    & > *:not(span) {
      border: 1px solid $grey-border-color;
      border-radius: 4px;
      padding: 6px;
    }
  }
}
</style>

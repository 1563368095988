<template>
	<Modal width="small" @close="emitCloseModal" @submit="saveCleaner" :texts="{ headerPrimary: modalTitle }">
		<section class="form-container">
			<div class="task-main-info">
				<template v-for="(input, idx) in modalInputs">
					<div class="input-container" :key="input.keyInCleaner + idx">
						<div class="input-img-wrapper">
							{{ $t(input.label) }}
						</div>
						<input
							class="modal-input"
							dir="auto"
							@click.right.prevent
							:class="input.keyInCleaner + '-input'"
							:placeholder="$t('insert_text')"
							type="text"
							v-model.trim="cleaner[input.keyInCleaner]"
						/>
						<ErrorMsg :isShown="isErrorShown(input.label)" :text="`${$t('please_enter')} ${$t(input.label)}`" />
					</div>
				</template>
			</div>
			<div class="task-secondary-info">
				<div class="input-container">
					<div>{{ $t('language') }}</div>
					<Select class="select-container" v-model="cleaner.lang" :src="langFlag">
						<Option
							v-for="(lang, idx) in languages"
							:key="lang.label + idx"
							:label="$t(lang.label)"
							:value="lang.value"
							:src="lang.logo_url"
						/>
					</Select>
					<ErrorMsg :isShown="isErrorShown('invalid_language')" :text="`${$t('invalid_language')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('supervisor') }}</div>
					<Select class="select-container" v-model="cleaner.userId">
						<Option
							v-for="(supervisor, idx) in supervisors"
							:key="supervisor.id + idx"
							:label="`${supervisor.firstName} ${supervisor.lastName}`"
							:value="supervisor.id"
						/>
					</Select>
					<ErrorMsg :isShown="isErrorShown('supervisor')" :text="`${$t('please_select')} ${$t('supervisor')}`" />
				</div>
				<div>
					<div>{{ $t('device_type') }}</div>
					<Select class="select-container" v-model="cleaner.device">
						<Option v-for="(type, idx) in deviceTypes" :key="type + idx" :label="$t(type)" :value="type" />
					</Select>
				</div>
				<div>
					<div>{{ $t('working_areas') }}</div>
					<Select class="select-container" v-model="cleaner.workingAreaIds" :multiple="true">
						<Option
							v-for="(workingArea, idx) in workingAreas"
							:key="workingArea.id + idx"
							:label="workingArea.area_name"
							:value="workingArea.id"
						/>
					</Select>
				</div>
			</div>
			<div class="custom-checkbox">
				<div>{{ $t('active') }}</div>
				<Checkbox class="checkbox-container" v-model="cleaner.isActive" />
			</div>
		</section>
	</Modal>
</template>

<script>
import Modal from '@/modules/common/components/Modal'
import Option from '@/modules/common/components/Option'
import Select from '@/modules/common/components/Select'
import ErrorMsg from '@/modules/common/components/ErrorMsg'
import Checkbox from '@/modules/common/components/Checkbox'
export default {
	name: 'CleanerModal',
	components: { Modal, ErrorMsg, Option, Select, Checkbox },
	props: {
		cleanerProp: { type: Object, required: true },
		languages: { type: Array, required: true },
		supervisors: { type: Array, required: true },
		workingAreas: { type: Array, required: true }
	},
	data() {
		return {
			errors: [],
			cleaner: JSON.parse(JSON.stringify(this.cleanerProp)),
			deviceTypes: ['android', 'ios'],
			modalInputs: [
				{
					label: 'phone',
					keyInCleaner: 'phone'
				}
			]
		}
	},
	methods: {
		emitCloseModal() {
			this.$emit('closeModal')
		},
		checkForm() {
			this.errors = []
			const { phone, lang, userId: supervisor } = this.cleaner
			if ((phone && !/^\d+$/.test(phone)) || phone.length < 10 || !phone) this.addError('phone')
			if (!lang) this.addError('invalid_language')
			if (!supervisor) this.addError('supervisor')
		},
		saveCleaner() {
			this.checkForm()
			if (this.errors.length > 0) return
			this.$emit('save', this.cleaner)
		},
		addError(error) {
			this.errors.push(error)
		},
		isErrorShown(error) {
			return this.errors.includes(error)
		}
	},
	computed: {
		modalTitle() {
			return this.cleaner.id ? this.$t('edit_cleaner') : this.$t('new_cleaner')
		},
		langFlag() {
			const { lang } = this.cleaner
			return this.languages.find((language) => language.value === lang)?.logo_url
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins';

.form-container {
	.task-main-info,
	.task-secondary-info {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}
}
.input-container {
	margin-bottom: 15px;
	input {
		@include modal-input;
		padding: 0 10px;
		width: 10vw;
	}
}

.md-field.select-container {
	width: 100% !important;
}

.select-container {
	border-bottom: 1px solid $grey-border-color;
}

.custom-checkbox {
	display: flex;
	align-items: center;
	gap: 5px;
	.checkbox-container {
		height: 1.1vw;
		width: 1.1vw;
	}
}
</style>

<template>
  <Modal
    width="small"
    @close="emitCloseModal"
    :texts="{ headerPrimary: room.id ? $t('edit_room') : $t('new_room') }"
    @submit="emitRoom"
    :isConfirmDisabled="isConfirmDisabled"
  >
    <section class="form-container">
      <div class="room-main-info">
        <template v-for="formItem in roomForm">
          <div class="input-container" :key="formItem.keyInRoom">
            <div>{{ $t(formItem.label) }}</div>
            <input
              type="text"
              class="modal-input"
              v-model="room[formItem.keyInRoom]"
            />
            <ErrorMsg
              :key="formItem.keyInRoom + 2"
              :isShown="errors.includes(formItem.label)"
              :text="`enter ${$t(formItem.label)}`"
            />
          </div>
        </template>
      </div>
      <div class="room-secondary-info">
        <div class="input-container">
          <div>{{ $t('gender') }}</div>
          <Select v-model="room.gender" class="select-container">
            <Option
              v-for="gender in genderList"
              :key="gender"
              :label="gender"
              :value="gender"
            />
          </Select>
          <ErrorMsg
            :isShown="errors.includes('gender')"
            text="please enter gender"
          />
        </div>
        <template v-if="room.id && surveys.length">
          <div class="input-container">
            <div>{{ $t('pad_survey') }}</div>
            <Select v-model="room.pad_survey_id" class="select-container">
              <Option
                v-for="survey in surveys"
                :key="survey.id"
                :label="survey.name"
                :value="survey.id"
              />
            </Select>
          </div>
          <div class="input-container">
            <div>{{ $t('tablet_survey') }}</div>
            <Select v-model="room.survey_id" class="select-container">
              <Option
                v-for="survey in surveys"
                :key="survey.id"
                :label="survey.name"
                :value="survey.id"
              />
            </Select>
          </div>
          <div class="input-container">
            <div>{{ $t('no_shift_survey') }}</div>
            <Select v-model="room.no_shift_survey_id" class="select-container">
              <Option
                v-for="survey in surveys"
                :key="survey.id"
                :label="survey.name"
                :value="survey.id"
              />
            </Select>
            <ErrorMsg
              :isShown="errors.includes('survey')"
              :text="`enter relevant ${$t('survey')}`"
              />
          </div>
        </template>
      <div class="input-container flex align-center">
        <div>{{ $t('calculated_room') }}</div>
        <CheckBox
          v-model="room.is_calculated"
          class="custom-checkbox"
        />
      </div>
        <div v-if="!room.id" class="input-container flex align-center">
        <div>{{ $t('including_pad9') }}</div>
        <CheckBox
          v-model="room.including_pad"
          class="custom-checkbox"
        />
      </div>
    </div>
    </section>
  </Modal>
</template>

<script>
import Modal from '../../common/components/Modal';
import Select from '../../common/components/Select';
import CheckBox from "../../surveys/components/CheckBox";

import Option from '../../common/components/Option';
import ErrorMsg from '../../common/components/ErrorMsg';
import { roomForm } from '../consts';

export default {
  props: {
    roomProp: { type: Object, required: true },
    surveys: { type: Array, required: false },
  },
  data() {
    return {
      room: {
        display_name: '',
        display_name_en: '',
        gender: '',
        building: '',
        floor: '',
        zone: '',
        site_id: '',
        cells_num: null,
        sinks_num: null,
        urinals_num: null,
        toilet_paper_size: null,
        is_calculated: true,
        including_pad:false,
      },
      isConfirmDisabled: false,
      roomForm,
      genderList: ['shared toilet', 'men toilet', 'women toilet', 'handicapped toilet', 'kitchen', 'tablet', 'coffee machine', 'meeting room', 'office', 'logistics', 'area', 
      'cafeteria', 'lobby', 'male', 'vending machine', 'clean room', 'cubicle', 'elevator', 'gym'
      ],
      errors: [],
    };
  },
  created() {
    this.room = JSON.parse(JSON.stringify(this.roomProp));
    this.room.is_calculated = !!this.room.is_calculated;
  },
  methods: {
    emitCloseModal() {

      this.$emit('closeModal');
    },
    checkForm() {
      const { id, gender, display_name, display_name_en, survey_id, pad_survey_id, no_shift_survey_id } = this.room;
      this.errors = [];
      if (!display_name) this.errors.push('room_name')
      if (!gender) this.errors.push('gender')
      if (id && !survey_id && !pad_survey_id && !no_shift_survey_id) {
        this.errors.push('survey')
      }
      if (display_name_en && !/^[a-zA-Z0-9\s]*$/.test(display_name_en)) {
        this.errors.push('room_name_en');
        return
      }
    },
    emitRoom() {
      this.checkForm();
      if (this.errors.length) return;
      this.isConfirmDisabled = true;
      this.$emit('submit', this.room);
    },
  },
  components: { Modal, Option, Select, ErrorMsg, CheckBox },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins';

.form-container { 
  display: flex;
  .room-main-info {
    display: flex;
    flex-wrap: wrap;
    gap:10px;
  }
  .room-secondary-info {
    display: flex;
    flex-wrap: wrap;
    gap:10px;
  }
}

.error-msg {
  @include error-msg;
}

.md-field.select-container {
  width: 100% !important;
}

.select-container {
  border-bottom: 1px solid $grey-border-color;
}

.input-container {
  margin-bottom: 15px;
  input {
    @include modal-input;
    padding: 0 10px;
    width: 10vw;
  }

  div {
    @include modal-input-label;
    margin-bottom: 0;
    padding:5px;
  }
}

.flex {
  display: flex;
}
.align-center{
  align-items: center;
}
.custom-checkbox {
height:1.4vw;
width: 1.4vw;
}
</style>

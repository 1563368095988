<template>
  <section class="charts-container">
    <table>
      <tr>
        <th>{{ $t('general') }}</th>
        <th v-if="this.chartListData.cleaning.labels.length">{{ $t('cleaning') }}</th>
        <th v-if="this.chartListData.maintenance.labels.length">{{ $t('maintenance') }}</th>
      </tr>
      <tr v-if="chartListData.general" class="no-print-charts">
        <td v-for="(chartData, key) in chartListData" :key="key">
          <Chart
            :isClickable="key !== 'general'"
            v-if="chartData.labels && chartData.labels.length"
            type="doughnut"
            :labels="chartData.labels"
            :datasets="chartData.datasets"
            :options="key === 'general' ? { ...chartOptions, hover: '' } : chartOptions"
            height="23vh"
            width="25vw"
            :isPlugin="true"
            @click="handleChartClick"
            :ref="'chartContainer' + key"
            @doneRender="$emit('mounted')"
          />
        </td>
      </tr>
      <!-- charts that print in the pdf because the top charts cannot rerender on print -->
      <tr v-if="chartListData.general" class="print-charts">
        <td v-for="(chartData, key) in chartListData" :key="key">
          <Chart
            :isClickable="key !== 'general'"
            v-if="chartData.labels && chartData.labels.length"
            type="doughnut"
            :labels="chartData.labels"
            :datasets="chartData.datasets"
            :options="key === 'general' ? { ...chartOptions, hover: '' } : chartOptions"
            width="400px"
            :isPlugin="true"
            @click="handleChartClick"
            :ref="'chartContainer' + key"
            @doneRender="$emit('mounted')"
          />
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import Chart from '@/modules/common/components/Chart'
import { capitalize } from '@/modules/common/services/utilService'
import scssVars from '@/styles/vars.scss'
import { analyticsChartColors } from '../consts/consts'

export default {
  data() {
    return {
      chartColors: analyticsChartColors,
      chartListData: {},
      chartOptions: {
        centerText: { display: true, text: 'No DATA' },
        elements: { center: { text: 'TEST' } },
        legend: { position: 'right', display: false },
        layout: { padding: { top: 30, bottom: 30 } },
        hover: {
          onHover: function(e) {
            var point = this.getElementAtEvent(e)
            if (point.length) e.target.style.cursor = 'pointer'
            else e.target.style.cursor = 'default'
          },
        },
        tooltips: {
          callbacks: {
            label: (item, tableData) => {
              let str = ''
              str += tableData.labels[item.index] + ' '
              str += +tableData.datasets[0].data[item.index]
              return str
            },
          },
          bodyFontSize: 14,
          titleFontSize: 14,
          backgroundColor: scssVars.lightGrey,
          titleFontColor: scssVars.blackBlue,
          bodyFontColor: scssVars.blackBlue,
        },
        //labels as the designer did! to put back remove from comment & give Chart component prop isPlugin="true"
        plugins: {
          datalabels: {
            labels: { font: { size: '0.875rem' }, display: 'none' },
            color: '#32355A',
            opacity: 0.5,
            anchor: (context) => (!context.chart.data.labels[1] ? 'start' : 'end'),
            align: (context) => (!context.chart.data.labels[1] ? 'start' : 'end'),
            formatter: (value, context) => {
              if (!context.chart.data.datasets[0]._meta) return
              const totalIndex = Object.keys(context.chart.data.datasets[0]._meta)[0]
              const total = context.chart.data.datasets[0]._meta[totalIndex].total
              let percentageNum = Math.round((context.chart.data.datasets[0].data[context.dataIndex] / total) * 100)
              let label = context.chart.data.labels[context.dataIndex]
              if (!context.chart.data.labels[1]) return label
              else if (percentageNum < 5) return ''
              else return `${label} ${percentageNum}%`
            },
          },
        },
      },
    }
  },
  props: {
    data: { type: Object, required: true },
  },
  created() {
    this.updateComponent()
  },
  watch: {
    data() {
      this.updateComponent()
    },
  },
  methods: {
    handleChartClick(chartData) {
      this.$emit('chartClick', chartData)
    },
    updateComponent() {
      if (!this.data) return
      const chartListData = { general: {}, cleaning: {}, maintenance: {} }
      let signals = { cleaning: 0, maintenance: 0, totalLikes: 0 }
      for (let key in this.data) {
        if (this.data[key].data) {
          this.data[key].data.forEach((a) => (signals[key] = signals[key] + a))
          let labels
          if (this.data[key].localLabels && this.data[key].localLabels[0]) labels = this.data[key].localLabels
          else if (this.data[key].labels[0]) labels = this.data[key].labels
          else labels = [] //["No Data"],
          chartListData[key] = {
            labels: labels,
            datasets: [
              {
                data: this.data[key].data[0] ? this.data[key].data : [1],
                backgroundColor: this.data[key].data[0] ? this.chartColors[key] : '#E9E9E9',
              },
            ],
          }
        } else signals[key] = signals[key] + this.data[key]
      }
      const likesProblemsChart = {
        labels: Object.keys(signals).map((a) => {
          if (a === 'totalLikes') return this.$t('like')
          else return capitalize(this.$t(a))
        }),
        datasets: [
          {
            data: Object.values(signals),
            backgroundColor: this.chartColors['likes'],
          },
        ],
      }
      if (!signals.cleaning && !signals.maintenance && !signals.totalLikes) {
        likesProblemsChart.labels = [`No Data`]
        likesProblemsChart.datasets[0].data = [1]
        likesProblemsChart.datasets[0].backgroundColor = ['#E9E9E9']
      }
      chartListData.general = likesProblemsChart
      this.chartListData = chartListData
      this.$store.commit('setChartListData', chartListData)
    },
  },
  components: { Chart },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.charts-container {
  table {
    width: 79vw;
    height: 15vw;
    border: $table-border;
    border-inline-end: unset;
    border-radius: 8px;
    border-collapse: unset;

    tr > * {
      border-inline-end: $table-border;
      flex-grow: 1;
    }

    .no-print-charts {
      @media print {
        display: none;
      }
    }

    .print-charts {
      @include chart-for-print;
    }

    th {
      height: 4.6vh;
      padding-top: 1.3vh;
      text-align: start;
      color: #7f7f7f;
      border-bottom: $table-border;
      padding-inline-start: 25px;
    }
  }
}

.chart-legends {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  margin-inline-start: 40px;
  .color-description {
    margin-inline-end: 10px;
    width: 13px;
    height: 13px;
  }
}
</style>

<template>
  <section class="shift-table">
    <div class="table-desc">
      <div class="title">{{ $t('shift_parameters') }}</div>
      <div class="buttons">
        <Button :isClean="true" @click="openModal" skin="primary" :dense="true">{{ $t('add_shift') }}</Button>
        <Button :isClean="true" @click="addReflactiveShifts" skin="primary" :disabled="isProcessingReflactiveShifts" :dense="true">
          <div class="inactive-button">
            <span>{{ $t('add_shift_inactive') }}</span>
            <Transition name="slide-fade">
              <md-progress-spinner v-if="isProcessingReflactiveShifts && !isRemovingReflactiveShifts" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            </Transition>
          </div>
        </Button>
        <Button class="delete" v-if="isAutomatedRelactiveShifts" :title="$t('remove_auto_shift_inactive')" :isClean="true" @click="removeReflactiveShifts" :dense="true">
          <div class="inactive-button">
            <img :src="require('@/assets/delete-icon.svg')" :class="{ grayed: !isAutomatedRelactiveShifts || isProcessingReflactiveShifts }" alt="remove-icon" />
            <Transition name="slide-fade">
              <md-progress-spinner v-if="isRemovingReflactiveShifts" class="md-accent " :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            </Transition>
          </div>
        </Button>
      </div>
    </div>
    <Table :tableData="i18nShiftData" class="shift-params-table" headerFontSize="11px" bodyFontSize="15px" :editOptions="{ edit: permitToEdit, remove: permitToEdit }" @editRow="openEditModal" @removeRow="removeShift" idField="id" :customRowGridStr="'80px 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px 50px'" />

    <Modal :parent="shiftParentType" width="small" v-if="isAddingShift" @close="closeModal" :texts="{ headerPrimary: $t(headerTxt), headerSecondary: `/ ${companyName}` }" @submit="submitShift" :isConfirmDisabled="isConfirmDisabled">
      <div class="form-container">
        <div class="input-container" v-for="(formItem, index) in shiftForm.filter(({ type }) => type === 'primary')" :key="index" :style="{ 'grid-column-start': index < 4 ? 'span 3' : 'span 4' }">
          <div class="input-label">{{ $t(formItem.label) }}</div>
          <Input class="input" :type="formItem.inputType" v-model.number="shift[formItem.keyInShift]" :style="{ marginBottom: 0 }" />
          <ErrorMsg :isShown="errors.includes(formItem.keyInShift)" :text="`${$t('please_enter')} ${$t(formItem.label)}`" />
        </div>
        <div class="days-of-week">
          <div class="input-label">{{ $t('days') }}</div>
          <div v-for="(day, index) in daysOfWeek" :key="index" class="checkbox-container">
            <label> <input type="checkbox" class="days" @click="daySelected($event.target.checked, day.value)" :checked="isChecked(day.value)"/></label>
            <span>{{ $t(`weekMap[${day.value}]`) }}</span>
          </div>
          <ErrorMsg :isShown="errors.includes('week_arrivals')" :text="`${$t('please_enter')} ${$t('days')}`" />
        </div>
        <div class="input-container active-calc-container">
          <label><input type="checkbox" v-model="shift.is_active" class="active-calc"/></label>
          {{ $t(`active_calculation`) }}
        </div>
        <div v-if="!roomId" class="multiselect-container">
          <transition name="fade">
            <label class="field full">
              <span>{{ $t('shift_rooms') }}</span>
              <div class="multi-select">
                <MultiSelectDialog v-if="openSelectMenu" :data="rooms" :initialSelected="selectedRooms" fieldName="name" idFieldName="id" @selected="handleMultiSelectInput($event)" @close="toggleSelect()" />
                <div @click="toggleSelect()" class="multi-select-input">
                  <template v-for="item in selectedRooms">
                    <span @click.stop :key="item.id" class="item" v-if="item.name !== 'All'">
                      <span>{{ item.name }}</span>
                      <span class="close-btn" @click.stop="removeItemFromSelected('selectedRooms', item.id)">x</span>
                    </span>
                  </template>
                </div>
              </div>
            </label>
          </transition>
        </div>
        <template v-if="isMoreOptions">
          <Transition name="fade" appear :duration="{ enter: 500, leave: 200 }">
            <div class="trans-container">
              <div class="input-container" v-for="(formItem, index) in shiftForm.filter(({ type }) => type !== 'primary')" :key="'input' + index" :style="{ 'grid-column-start': index < 4 ? 'span 3' : 'span 4' }">
                <div class="input-label">{{ $t(formItem.label) }}</div>
                <Input class="input" :type="formItem.inputType" v-model.number="shift[formItem.keyInShift]" :style="{ marginBottom: 0 }" />
                <ErrorMsg :isShown="errors.includes(formItem.keyInShift)" :text="`${$t('please_enter')} ${$t(formItem.label)}`" />
              </div>
            </div>
          </Transition>
        </template>
      </div>
      <template v-slot:footer>
        <Button :isClean="true" @click="isMoreOptions = !isMoreOptions" skin="primary" :isOpen="isMoreOptions">{{ $t('additional_options') }}</Button>
      </template>
    </Modal>
  </section>
</template>

<script>
import shiftService from '../services/shiftService'
import swalService from '@/modules/common/services/swalService'
import ErrorMsg from '@/modules/common/components/ErrorMsg'
import Table from '@/modules/common/components/customTable/Table'
import MultiSelectDialog from '@/modules/common/components/MultiSelectDialog'
import Input from '@/modules/common/components/Input'
import { shiftForm, daysOfWeek, shiftColsToShow } from '../consts'
import Modal from '@/modules/common/components/Modal'
import roomService from '../services/roomService'
import siteService from '../services/siteService'
import Checkbox from '../../common/components/Checkbox.vue'
import Button from '../../common/components/Button.vue'

export default {
  props: {
    shiftParentType: {
      type: String,
      required: true,
      validator: function(value) {
        // The value must match one of these strings
        return ['room', 'site', 'shift'].includes(value)
      },
    },
    siteId: { type: Number, required: true },
    companyId: { type: Number, required: true },
    roomId: { type: Number, required: false },
    companyName: { type: String, required: false },
  },
  data() {
    return {
      shift: this.getDefaultShift(),
      isAddingShift: false,
      shiftData: [],
      i18nShiftData: [],
      shiftForm,
      daysOfWeek,
      errors: [],
      headerTxt: '',
      openSelectMenu: false,
      rooms: [],
      selectedRooms: [],
      shiftRoomIds: [],
      isConfirmDisabled: false,
      isMoreOptions: false,
      isProcessingReflactiveShifts: false,
      isRemovingReflactiveShifts: false,
    }
  },
  async created() {
    await this.updateViewData()
  },
  methods: {
    async updateViewData() {
      this.shiftRoomIds = []
      let res = await shiftService.getShifts(this.siteId, this.roomId)
      const rooms = await roomService.getRooms({ site: this.siteId })

      if (!res.length)
        res = [
          {
            arrivals_percent: '',
            complaints_percent: '',
            day_arrivals: '',
            week_arrivals: '[]',
            sla_percent: '',
            end_hour: '',
            max_sla: '',
            min_sla: '',
            start_hour: '',
            id: '',
            shift_name: 'placeholder',
            shift_number: '',
          },
        ]

      this.rooms = rooms.map(({ id, display_name }) => ({ id, name: display_name }))
      this.shiftData = JSON.parse(JSON.stringify(res)).map((item) => {
        if (item.rooms_ids && item.rooms_ids.length) {
          this.shiftRoomIds = [...this.shiftRoomIds, ...item.rooms_ids]
        }
        item.week_arrivals = JSON.parse(item.week_arrivals)
        return item
      })
      res = res.map((item) => {
        const { id } = item
        const sortedItem = {}
        shiftColsToShow.forEach((key) => (sortedItem[key] = item[key]))

        for (const property in sortedItem) {
          if (shiftColsToShow.includes(property)) {
            sortedItem[this.$t(property)] = sortedItem[property]
          }
          delete sortedItem[property]
        }
        return { ...sortedItem, id }
      })

      this.i18nShiftData = res
      this.selectedRooms = []
      this.$emit('loaded')
    },
    openEditModal(item) {
      const shiftToEdit = this.shiftData.find((a) => a.id === item.id)
      this.shift = { ...shiftToEdit }
      if (this.shift.rooms_ids && this.shift.rooms_ids.length && this.rooms.length) {
        const mappedIds = this.shift.rooms_ids.reduce((acc, curr) => ((acc[curr] = true), acc), {})
        this.rooms.forEach((room) => {
          if (mappedIds[room.id]) this.selectedRooms.push(room)
        })
      }
      this.headerTxt = 'edit_shift'
      this.isAddingShift = true
    },
    openModal() {
      this.shift = this.getDefaultShift()
      this.headerTxt = 'new_shift'
      this.isAddingShift = true
    },
    closeModal(headerTxt) {
      this.shift = this.getDefaultShift()
      this.errors = []
      this.selectedRooms = []
      this.isAddingShift = false
      this.openSelectMenu = false
    },
    daySelected(checkboxValue, dayValue) {
      const dayIndex = this.shift.week_arrivals.indexOf(dayValue)
      const isSelected = dayIndex !== -1
      if (isSelected) this.shift.week_arrivals.splice(dayIndex, 1)
      else this.shift.week_arrivals.push(dayValue)
    },
    isChecked(dayValue) {
      if (this.shift.week_arrivals.includes(dayValue)) return true
      else return false
    },
    async removeShift(id) {
      const result = await swalService.confirmMsg()
      if (!result.value) return
      let roomId = null
      if (this.roomId && this.shiftRoomIds.length) {
        const shiftPerRoom = this.shiftRoomIds.some((id) => +id === +this.roomId)
        if (shiftPerRoom) roomId = this.roomId
      }
      try {
        await shiftService.removeShift(id, roomId)
        swalService.deletedMsg()
        this.updateViewData()
      } catch (err) {
        swalService.errorMsg()
      }
    },
    checkForm() {
      const { week_arrivals, shift_name, shift_number, start_hour, end_hour, min_sla, max_sla, day_arrivals, save_arrivals, complaints_percent, sla_percent, arrivals_percent } = this.shift
      this.errors = []
      if (!week_arrivals.length) this.errors.push('week_arrivals')
      if (!shift_name) this.errors.push('shift_name')
      if (!shift_number) this.errors.push('shift_number')
      if (!start_hour && start_hour !== 0) this.errors.push('start_hour')
      if (!end_hour && end_hour !== 0) this.errors.push('end_hour')
      if (!min_sla) this.errors.push('min_sla')
      if (!max_sla) this.errors.push('max_sla')
      if (!day_arrivals) this.errors.push('day_arrivals')
      if (!save_arrivals) this.errors.push('save_arrivals')
      if (!complaints_percent && complaints_percent !== 0) this.errors.push('complaints_percent')
      if (!sla_percent && sla_percent !== 0) this.errors.push('sla_percent')
      if (!arrivals_percent && arrivals_percent !== 0) this.errors.push('arrivals_percent')
    },
    async submitShift() {
      this.checkForm()
      if (this.errors.length) return
      this.isConfirmDisabled = true
      if (!this.roomId) this.shift.rooms_shift = this.selectedRooms.length ? this.selectedRooms : this.rooms
      try {
        await shiftService.saveShift(this.shift)
        this.isConfirmDisabled = false
        swalService.savedMsg(this.$t('Your work has been saved'))
        this.updateViewData()
      } catch (err) {
        swalService.errorMsg()
        this.isConfirmDisabled = false
      }
      this.isAddingShift = false
    },
    handleMultiSelectInput(values, field) {
      if (!values) return
      this.selectedRooms = values
    },
    toggleSelect() {
      this.openSelectMenu = !this.openSelectMenu
    },
    removeItemFromSelected(objName, id) {
      const idx = this[objName].findIndex((item) => item.id === id)
      if (idx !== -1) this[objName].splice(idx, 1)
    },
    getDefaultShift() {
      return {
        week_arrivals: [],
        room_id: this.roomId,
        site_id: this.siteId,
        company_id: this.companyId,
        is_active: true,
        min_sla: '15',
        max_sla: '60',
        day_arrivals: '3',
        save_arrivals: '3',
        complaints_percent: '35',
        sla_percent: '35',
        arrivals_percent: '30',
        complaints_percent_no_visits: '20',
        sla_percent_no_visits: '60',
        arrivals_percent_no_visits: '20',
        shift_number: this?.shiftData?.[0].shift_name === 'placeholder' ? 1 : this?.shiftData?.length + 1,
      }
    },
    async addReflactiveShifts() {
      try {
        this.isProcessingReflactiveShifts = true
        await siteService.addReflactiveShifts(this.siteId, this.companyId, this.language)
        await this.updateViewData()
        this.isProcessingReflactiveShifts = false
        swalService.savedMsg(this.$t('Your work has been saved'))
      } catch (error) {
        this.isProcessingReflactiveShifts = false
        swalService.errorMsg('Could not add reflactive shifts.')
      }
    },
    async removeReflactiveShifts() {
      try {
        this.isProcessingReflactiveShifts = true
        this.isRemovingReflactiveShifts = true
        await siteService.removeReflactiveShifts(this.siteId, this.companyId)
        await this.updateViewData()
        this.isProcessingReflactiveShifts = false
        this.isRemovingReflactiveShifts = false
        swalService.savedMsg(this.$t('Your work has been saved'))
      } catch (error) {
        this.isProcessingReflactiveShifts = false
        this.isRemovingReflactiveShifts = false
        swalService.errorMsg('Could not delete reflactive shifts.')
      }
    },
  },
  computed: {
    language() {
      return JSON.parse(localStorage.getItem('language') || 'en')
    },
    permitToEdit() {
      if (!this.roomId) return true
      else if (this.shiftRoomIds.length && this.roomId) {
        return this.shiftRoomIds.includes(+this.roomId)
      } else return true
    },
    isAutomatedRelactiveShifts() {
      return this?.shiftData.some(({ isAutomated }) => isAutomated)
    },
  },
  components: {
    Modal,
    Input,
    Table,
    ErrorMsg,
    MultiSelectDialog,
    Checkbox,
    Button,
  },
  watch: {
    siteId: {
      handler() {
        this.updateViewData()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.shift-table {
  .secondary-title {
    font-size: 24px !important;
  }
}

#app:has(.days-of-week) .search-bar .md-field {
  margin: unset;
  input {
    max-height: unset !important;
    height: unset !important;
  }
}

#app:has(.days-of-week) .md-field input {
  min-height: 40px !important;
  display: flex !important;
}

.buttons {
  .delete {
    background-color: #f1f1f1 !important;
  }

  .md-button:hover:before {
    opacity: 0.05;
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins.scss';
$table-grey: #7f7f7f;
.shift-table {
  color: #7f7f7f;
  margin-inline-end: 10px;
  // text-transform: capitalize;
  display: flex;
  flex-direction: column;
  .table-desc {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;

    .title {
      color: $table-grey;
      font-weight: bold;
    }

    .buttons {
      display: flex;
      gap: 15px;
    }

    .add-button {
      cursor: pointer;
      color: $blue;
      line-height: unset;
    }
  }
  .shift-params-table {
    border: 1px solid #e7e7e7;
    overflow-y: scroll;
    flex-grow: 1;
    @include scroll;
  }

  .shift-holiday & {
    flex-basis: 250%;
  }
}

.form-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 1fr 1fr;

  .input-label {
    margin-bottom: 10px;
  }

  .input-container {
    margin-inline-end: 6px;
    &.active-calc-container {
      grid-column: 1/5;
      grid-row: 4/5;
    }

    .input {
      border: 1px solid $grey-border-color;
      border-radius: 4px;
      padding: 0 10px;
      height: 46px;
    }
  }

  .days-of-week {
    grid-column: 1/5;
    grid-row: 2 / 4;
    &,
    .input-container {
      margin-bottom: 20px;
    }
  }
}

.checkbox-container,
.active-calc-container {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 23px;

  width: unset;
  height: unset;
  border: unset;
  background-color: unset;
  border-radius: unset;
  margin: unset;
  justify-content: unset;

  input.days,
  input.active-calc {
    display: none;
  }

  label:has(input.days, input.active-calc) {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    cursor: pointer;
  }

  label:has(input.days, input.active-calc)::after {
    position: relative;
    display: flex;
    width: 15px;
    height: 15px;
    content: '\2714';
    font-size: 13px;
    color: #fff;
    border-radius: 4px;
    background-color: rgba(0, 130, 243, 1);
    align-items: center;
    justify-content: center;
    margin-inline-start: -1px;
    margin-block-start: -1px;
    opacity: 0;
    padding-block-start: 1px;
    transition: opacity 0.2s ease-in-out;
  }

  label:has(input.days:checked, input.active-calc:checked)::after {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
  }
}

.multiselect-container {
  grid-column: 4/13;
  grid-row: 2/5;

  .field.full {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }

  .multiselect-dialog {
    height: 200px;
  }

  .multi-select-input {
    cursor: pointer;
    @include scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border-radius: 4px;
    overflow-x: hidden;
    height: 100%;
    padding-inline: 6px;
    padding-block: 5px;
    .item {
      margin-bottom: 2px;
      margin-top: 2px;
      span {
        max-height: 40px;
      }
      .close-btn {
        margin-inline-start: 20px;
        cursor: pointer;
      }
      margin-inline-end: 7px;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 4px;
      background-color: $grey-border-color;
    }
  }
  .multi-select {
    width: 100%;
    height: 100%;
    position: relative;
    max-height: 200px;
    max-width: 490px;
    @include border($grey-border-color);
    .dialog {
      position: absolute;
      max-width: 300px;
      right: 0;
      @include rtl {
        right: unset;
        left: 0;
      }
    }
  }
}

.trans-container {
  display: flex;
  flex-wrap: wrap;
  grid-column-start: span 12;
  max-width: 665px;
  padding-block-start: 40px;

  .input-container {
    margin-block-end: 10px;
  }
}

.inactive-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.slide-fade-enter-active {
  transition: all 0.9s linear;
  animation: none;
}

.slide-fade-leave-active {
  transition: all 0.1s linear;
  animation: none;
}

.grayed {
  filter: grayscale(1);
}
</style>
